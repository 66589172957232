/* Wooden fish zoom effect */
.w-f-c-i-size {
  animation: wooden-fish-size 0.3s;
}

@keyframes wooden-fish-size {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* Floating text effect */
.floating-text {
  position: absolute;
  color: red;
  font-size: 30px;
  opacity: 1;
  animation: fade-out 2s forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Wooden fish container */
.wooden-fish-container {
  position: relative; /* Changed from absolute to relative */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px; /* Add margin at the bottom to separate it from the table */
}

/* Open Positions Table */
.positions-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.positions-table th, .positions-table td {
  padding: 12px;
  text-align: center;
  border: 1px solid #ddd;
}

.positions-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.positions-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.positions-table tbody tr:hover {
  background-color: #f1f1f1;
}

.positions-table td {
  color: #333;
}

/* Optional: Add a subtle animation to the table rows when hovering */
.positions-table tbody tr:hover td {
  transition: background-color 0.3s ease;
}

/* Ensure the wooden fish stays at the bottom with no overlap */
.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dashboard > * {
  margin-bottom: auto;
}

.wooden-fish-container {
  margin-top: auto;
  margin-bottom: 50px; /* Space between the wooden fish and the table */
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .positions-table th, .positions-table td {
    padding: 8px;
    font-size: 14px;
  }

  .floating-text {
    font-size: 16px;
  }
}
